import RequestService from '../services/RequestService'

let defaultFilters = JSON.stringify({
  requestsDateRange: { startDate: null, endDate: null },
  eventsDateRange: { startDate: null, endDate: null },
  status: null,
  purchased: null,
  users: [],
  managers: [],
  unitPrice: { min: null, max: null },
  totalPriceWithFees: { min: null, max: null },
  eventIds: { excluded: false, list: [] },
  venues: { excluded: false, list: [] },
  eventNames: '',
  sectionRow: '',
  showOtherFilters: false,
  teams: null,
  services: [],
  isRequestHasDisclosure: false
});

let filters = {
  localStorageVersion: 4,
  newRequests: {...JSON.parse(defaultFilters), filterName: 'newRequests'},
  approvedRequests: {...JSON.parse(defaultFilters), filterName: 'approvedRequests'},
  declinedRequests: {...JSON.parse(defaultFilters), filterName: 'declinedRequests'},
  expiredRequests: {...JSON.parse(defaultFilters), filterName: 'expiredRequests'},
}

function loadFiltersFromStorage () {
  try {
    if (localStorage.filters) {
      let allFilters = JSON.parse(localStorage.filters);
      if (allFilters.localStorageVersion === filters.localStorageVersion) {
        filters = allFilters;
      }
    }
  } catch (e) {
    console.log('invalid filters: ' + e);
  }
}
loadFiltersFromStorage();

function saveFiltersToLocalStorage(filters){
  localStorage.filters = JSON.stringify(filters);
}
let tableIsSticky = localStorage['tableIsSticky'] === 'true';
export default {
  namespaced: true,

  state: {
    isLoading: false,
    currentTub: 'newRequests',
    newRequests: [],
    approvedRequests: [],
    declinedRequests: [],
    expiredRequests: [],
    newRequestPagination: {},
    approvedRequestPagination: {},
    declinedRequestPagination: {},
    expiredRequestPagination: {},
    filters: filters,
    tableIsSticky: tableIsSticky,
  },

  actions: {
    getNewRequests({ commit }, data) {
      data.type = 1
      return RequestService.getRequests(data)
        .then(response => {
          commit('setNewRequests', response.data.items)
          commit('changeTimeLeftNewRequest')

          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    getApprovedRequests({ commit }, data) {
      data.type = 2
      return RequestService.getRequests(data)
        .then(response => {
          commit('setApprovedRequests', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    getDeclinedRequests({ commit }, data) {
      data.type = 3
      return RequestService.getRequests(data)
        .then(response => {
          commit('setDeclinedRequests', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    getExpiredRequests({ commit }, data) {
      data.type = 4
      return RequestService.getRequests(data)
        .then(response => {
          commit('setExpiredRequests', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeRequests({ dispatch, getters, commit }, data) {
      const { ids, type } = data

      return RequestService.updateRequests(ids, type)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeRequest({ dispatch, getters, commit }, data) {
      const id = data.id
      if (!id) return ;

      commit('rmRequest', { id })

      return RequestService.updateRequest(id, data)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  },

  mutations: {
    setTableIsSticky(state) {
      state.tableIsSticky = !state.tableIsSticky;
      localStorage['tableIsSticky'] = state.tableIsSticky;
    },
    setCurrentTub(state, tab) {
      state.currentTub = tab;
    },
    setIsLoading(state, bool) {
      state.isLoading = bool;
    },
    setEventIdFilter(state, eventId) {
      state.filters[state.currentTub].eventIds.list = [eventId]
    },

    rmRequest(state, { id }) {
      state.newRequests = state.newRequests.map(el => {
        if (el.id === id) {
          el.disableActions = true
        }
        return el
      })
    },

    resetFilters(state, filterName) {
      let showOtherFilters = state.filters[filterName].showOtherFilters
      let teams = state.filters[filterName].teams
      state.filters[filterName] = {...JSON.parse(defaultFilters), filterName: filterName, showOtherFilters: showOtherFilters, teams: teams};
      saveFiltersToLocalStorage(state.filters);
    },

    saveFilters(state) {
      saveFiltersToLocalStorage(state.filters)
    },

    notifyRequest(state, id) {
      state.newRequests = state.newRequests.map(el => {
        if (el.id === id) {
          // el.notify = true
          // setTimeout(() => {
          //   el.notify = false
          // }, 5000)
        }

        return el
      })
    },

    setNewRequests(state, requests) {
      state.newRequests = requests
    },

    setApprovedRequests(state, requests) {
      state.approvedRequests = requests
    },

    setDeclinedRequests(state, requests) {
      state.declinedRequests = requests
    },

    setExpiredRequests(state, requests) {
      state.expiredRequests = requests
    },

    setNewRequestPagination(state, page) {
      state.newRequestPagination = page
    },

    setApprovedRequestPagination(state, page) {
      state.approvedRequestPagination = page
    },

    setDeclinedRequestPagination(state, page) {
      state.declinedRequestPagination = page
    },

    setExpiredRequestPagination(state, page) {
      state.expiredRequestPagination = page
    },

    changeTimeLeftNewRequest(state) {
      const now = Date.parse(new Date()) / 1000
      let newRequests = JSON.parse(JSON.stringify(state.newRequests))
      let newRequestsChanged = false

      for (let request of newRequests) {
        let timeLeft = null;
        if (request.time_left === null) {
          let currentDate = (new Date()).setMilliseconds(0);
          let nyDate = new Date((new Date(currentDate).toLocaleString("en-US", {timeZone: "America/New_York"})));
          let diff = (currentDate - nyDate) / 1000;
          timeLeft = (new Date(request.created_at))/ 1000 - now + diff;
        } else {
          timeLeft = request.time_left - now;
        }

        let minutes = Math.floor(timeLeft / 60);
        if (minutes < 0) minutes += 1;
        let seconds = Number(timeLeft - minutes * 60);

        if (seconds < 0) seconds *= -1;
        if (seconds < 10) seconds = '0' + seconds;
        if (timeLeft < 0 && seconds === 60) {
          seconds = '00';
          minutes -= 1;
        }
        if (timeLeft < 0 && minutes === 0) minutes = '-' + minutes;

        if (request.time_left !== null) {
          request.different = `${minutes}:${seconds}`;
          request.total_time_left = timeLeft;
        }

        newRequestsChanged = true;
      }

      if (newRequestsChanged) {
        state.newRequests = newRequests
      }
    }
  },

  getters: {
    tableIsSticky(state) {return state.tableIsSticky},
    tableIsStickyVh(state) {return state.tableIsSticky ? '60vh' : ''},
    isNewRequestsWithNegativeTimer(state) {return Boolean(state.newRequests.find(request => request.different < "0"))},
    isLoading(state){return state.isLoading},
    currentTub(state){return state.currentTub},
    newRequestsFilters(state){return state.filters.newRequests},
    approvedRequestsFilters(state){return state.filters.approvedRequests ?? {}},
    declinedRequestsFilters(state){return state.filters.declinedRequests},
    expiredRequestsFilters(state){return state.filters.expiredRequests},
    filters(state){return state.filters;},

    newRequests(state) {
      return state.newRequests
    },

    approvedRequests(state) {
      return state.approvedRequests
    },

    declinedRequests(state) {
      return state.declinedRequests
    },

    expiredRequests(state) {
      return state.expiredRequests
    },

    newRequestPagination(state) {
      return state.newRequestPagination
    },

    approvedRequestPagination(state) {
      return state.approvedRequestPagination
    },

    declinedRequestPagination(state) {
      return state.declinedRequestPagination
    },

    expiredRequestPagination(state) {
      return state.expiredRequestPagination
    },
  }
}
