import axiosIns from '/src/libs/axios'
import { saveAs } from 'file-saver'

class ReportService {

  getReports() {
    return axiosIns.get('reports')
      .then(response => {
        return response.data
      })
  }

  download(reportId) {
    return axiosIns.get(`reports/download/${reportId}`, { responseType: 'blob' })
      .then(response => {
        this.saveReport(response)
      })
  }

  saveMonthlyReport() {
    return axiosIns
      .get('monthly-report')
  }

  saveBuyerWeeklyReport(userList, splitTime) {
    return axiosIns
      .get('buyer-weekly-report', { params: { userList, splitTime} });
  }

  saveBuyerMonthlyReport(userList, splitTime) {
    return axiosIns
      .get('buyer-monthly-report', { params: { userList, splitTime } });
  }

  saveBuyerCustomReport(range, userList, splitTime) {
    return axiosIns
      .get('buyer-custom-report',{ params: { range, userList, splitTime }});
  }

  saveEventWeeklyReport(eventInfo) {
    return axiosIns
      .get('event-weekly-report', { params: { eventInfo } });
  }

  saveEventMonthlyReport(eventInfo) {
    return axiosIns
      .get('event-monthly-report', { params: { eventInfo } });
  }

  saveEventCustomReport(range, evenInfo) {
    return axiosIns
      .get('event-custom-report',{ params: {range: range, eventInfo: evenInfo }});
  }

  saveCompanyReport(data) {
    return axiosIns
      .get('company-report', { params: data });
  }

  saveMultipleCompanyReport(range, companies) {
    return axiosIns
      .get('multiple-company-report', { params: { range, companies }});
  }

  saveReport(response) {
    const { data, headers } = response
    let filename = headers['content-disposition'].split('filename=')[1]
    filename = filename.replaceAll('"', '')
    saveAs(data, filename)
  }

}

export default new ReportService
