import ReportService from "@/services/ReportService";

export default {
	namespaced: true,
	state: {
		reports: [],
	},

	actions: {
		getReports({ commit }, data) {
			return ReportService.getReports(data)
				.then(response => {
					commit('SET_REPORTS', response.items)
					return Promise.resolve(response.data)
				})
				.catch(error => {
					return Promise.reject(error)
				})
		},

		addItem({commit}, report) {
			commit('ADD_ITEM', report)
		},
		updateStatus({commit}, data) {
			commit('UPDATE_STATUS', data)
		},
		updateProgress({commit}, data) {
			commit('UPDATE_PROGRESS', data)
		},
	},

	mutations: {
		SET_REPORTS(state, reports) {
			state.reports = reports
		},
		ADD_ITEM(state, report) {
			if (state.reports.length >= 10) {
				state.reports.pop()
			}
			state.reports.push(report)
		},
		UPDATE_STATUS(state, {reportId, status}) {
			const report = state.reports.find(report => report.id === reportId)
			if (report) {
				report.status = status
			}
		},
		UPDATE_PROGRESS(state, {reportId, progress}) {
			const report = state.reports.find(report => report.id === reportId)
			if (report) {
				report.progress = progress
			}
		}
	},

	getters: {
		reports: state => state.reports.sort((a, b) => b.id - a.id),
	}
}
